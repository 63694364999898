<template>
    <div id="UserBasicInformation">
      <h4>Personal Details</h4>
        <div class="row">
            <div class="mb-3 col-12 col-md-6">
                <b-form-group
                    id="fieldset-1"
                    label="Email"
                    label-for="input-1"
                >
                    <b-form-input id="input-1" v-model="user.user.email" disabled></b-form-input>
                </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
                <b-form-group
                    id="fieldset-2"
                    label="Date of Birth"
                    label-for="input-2"
                >
                    <b-form-input id="input-2" type="date" v-model="user.user_profile.dob"></b-form-input>
                </b-form-group>
            </div>
        </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-1"
              label="Gender"
              label-for="input-3"
          >
            <b-form-select id="input-3" v-model="user.user_profile.gender" :options="gender_options"></b-form-select>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-2"
              label="Phone Number"
              label-for="input-4"
          >
            <b-form-input id="input-4" v-model="user.user_profile.phone" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-1"
              label="Education Level"
              label-for="input-5"
          >
            <b-form-select id="input-5" v-model="user.user_profile.education_level"
                          :options="education_options"></b-form-select>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Place of Birth"
          >
            <b-form-select v-model="user.user_profile.place_of_birth"
                           :options="ethnicity_options"></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-5"
              label="Relationship Status"
              label-for="input-8"
          >
            <b-form-select id="input-8" v-model="user.user_profile.relationship_status"
                          :options="relationship_options"></b-form-select>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Dependants"
          >
            <b-form-input type="number" v-model="user.user_profile.dependants"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-4"
              label="Organisation"
              label-for="input-7"
          >
            <b-form-input id="input-7" v-model="client.client_name" disabled></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Employment Status"
          >
            <b-form-select v-model="user.user_profile.employment_status"
                           :options="employment_options"></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-3"
              label="Occupation"
              label-for="input-6"
          >
            <b-form-input id="input-6" v-model="user.user_profile.occupation" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-2"
              label="Job Type"
              label-for="input-5"
          >
            <b-form-input id="input-5" v-model="user.user_profile.job_type" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <hr class="my-5" style="border: 2px solid #E83B75; border-radius: 15px;">
      <h4>Address Details</h4>
      <div class="row">
        <div class="mb-3 col-12">
          <b-form-group
              id="fieldset-6"
              label="Address"
              label-for="input-10"
          >
            <b-form-input id="input-10" v-model="user.user_profile.address" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row mb-5">
        <div class="mb-3 col-12 col-md-5">
          <b-form-group
              id="fieldset-7"
              label="City"
              label-for="input-1"
          >
            <b-form-input id="input-8" v-model="user.user_profile.city" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-4">
          <b-form-group
              id="fieldset-8"
              label="County"
              label-for="input-1"
          >
            <b-form-input id="input-8" type="text" v-model="user.user_profile.county" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-3">
          <b-form-group
              id="fieldset-7"
              label="Postal Code"
              label-for="input-1"
          >
            <b-form-input id="input-8" v-model="user.user_profile.postal_code" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <hr class="my-5" style="border: 2px solid #E83B75; border-radius: 15px;">
      <h4>Wellbeing Details</h4>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Do you have a disability?"
          >
            <b-form-select id="input-3" v-model="user.user_profile.disability" :options="disability_options"></b-form-select>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Please specify"
          >
          <b-form-input id="input-8" v-model="user.user_profile.disability_specify"
                        :disabled="!user.user_profile.disability"
                        placeholder="If yes, please specify"
                        trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Next of the Kin Full Name"
          >
            <b-form-input v-model="user.user_profile.next_of_the_kin_full_name" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              label="Next of the Kin Phone Number"
          >
            <b-form-input type="text" v-model="user.user_profile.next_of_the_kin_phone" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-7"
              label="General Practitioner Name"
              label-for="input-1"
          >
            <b-form-input id="input-8" v-model="user.user_profile.gp_name" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="mb-3 col-12 col-md-6">
          <b-form-group
              id="fieldset-8"
              label="General Practitioner Number"
              label-for="input-1"
          >
            <b-form-input id="input-8" type="text" v-model="user.user_profile.gp_phone" trim></b-form-input>
          </b-form-group>
        </div>
      </div>
      <b-button @click="updateUserDetails" class="cta-button px-5 py-3 my-4">{{ componentText.save }}</b-button>
      <Popup v-if="showUserDetailsPopup" :content="userDetailsPopupContent" v-on:close="hideUserDetailsPopup"></Popup>
    </div>

</template>

<script>

import axios from "axios";
import config from "@/config/constants";
import Popup from "@/components/Popup.vue"
import {mapState} from "vuex";

export default {
  name: "UserBasicInformation",
  components: {
    Popup
  },
  data() {
    return {
      loading: false,
      gender_options: [
        'Male',
        'Female',
        'Non-binary',
        'Other'
      ],
      education_options: [
        'Primary School',
        'Secondary / High School',
        'Bachelors Degree',
        'Masters Degree',
        'PhD',
        'Trade School',
        'Prefer not to say'
      ],
      ethnicity_options: [
        'Europe',
        'Africa',
        'Asia',
        'South America',
        'North/Central America',
        'Australia/NZ',
        'Caribbean Islands',
        'Pacific Islands',
        'Other',
        'Prefer not to say'
      ],
      relationship_options: [
        'Single',
        'in a relationship',
        'in a relationship and cohabiting'
      ],
      employment_options: [
        'Full Time Employed',
        'Part Time Employed',
        'Unemployed',
        'Free lancer',
        'Retired',
        'Prefer not to say'
      ],
      disability_options: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ],
      userDetailsPopupContent: {
        header: '',
        message: ''
      },
      showUserDetailsPopup: false,
    };
  },
  mounted() {
  },
  methods: {
    hideUserDetailsPopup(){
      this.showUserDetailsPopup = false
    },
    async updateUserDetails() {
      const payload = {
        user_id: this.$store.getters.user_id,
        user: this.user.user,
        user_profile: this.user.user_profile,
        user_settings: this.user.user_settings
      }
      let res = await axios.post(config.api_env + '/application/api/u/user/update-user-profile', payload, config.options)
          .then((response) => {
            this.userDetailsPopupContent.header = (response.data.success === true) ? 'Success!' : 'Error'
            this.userDetailsPopupContent.message = response.data.message
            this.showUserDetailsPopup = true
          }, (error) => {
            this.userDetailsPopupContent.header = 'Error'
            this.userDetailsPopupContent.message = error
            this.showUserDetailsPopup = true
          });
    },
  },
  computed: {
    ...mapState(['user', 'client']),
    componentText() {
      return this.getText.loggedIn.settings;
    },
  },
  watch: {},
};
</script>

<style lang="less">
#UserBasicInformation {
  min-height: 350px;
  padding-bottom: 20px;

  label {
    color: #322B5F;
  }

  .form-control {
    font-family: "DMSans", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
  }

  .form-control:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }

  .form-control:disabled {
    color: #d6d6d6;
  }
  .custom-select {
    font-family: "DMSans", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
  }

  .custom-select:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }
}
</style>
